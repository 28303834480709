import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import AboutPanel from "../components/about-panel"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import AppsPanel from "../components/apps-panel"


const PciPage = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="PCI Compliance Application Development Specialists" />
        <Hero title="On-Demand PCI Compliance Application Development Specialists" description="Hire Our team of software engineers With years of experience developing Secure Applications." image="pages/pci-hero.jpg" />
        <AboutPanel title="I can help you develop your PCI compliance software." description="Hire high-performing, on-demand Software engineers with PCI experience" image="pages/pci-developer.jpg"  />
        <AppsPanel description="Our team of experts can help you get your project up and running quickly and securely. With years of experience in the field, our developers are well-equipped to assist with all your needs. Contact us today to learn more!" />

      </Layout>
    </ThemeProvider>
  </React.Fragment>
)

export default PciPage
